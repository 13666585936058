import { ThemeProvider } from "@mui/material";
import "../../App.css";
import { Container, Box, Stack, Button } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Cláusula de Responsabilidad";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
                  <AppTitleOrange variant={!props.matches ? "h2" : "h3"} sx={{marginTop: "30px", marginBottom: "30px"}}>
                    Cláusula de Responsabilidad
                  </AppTitleOrange>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      m: "15px",
                    }}
                  >
                    <div style={{ textAlign: "justify" }}>
                      <p>
                        <strong>Planes Turísticos S.A.S.</strong>, con registro
                        Nacional de Turismo número 36964 expedido por el
                        Ministerio de Comercio Industria y Turismo, amparada y
                        regulada por el régimen de responsabilidad que establece
                        la Ley300 de 1996, modificada por la Ley 1558 de 2012,
                        el Decreto Reglamentario 1075 de 1997, Decreto 2438 de
                        2010, Ley 1480 de 2011 y demás decretos reglamentarios.
                        Manifestamos que actuamos únicamente como intermediarios
                        entre los viajeros y las entidades o personas llamadas a
                        facilitar los servicios que se indican en el portal web,
                        correspondiente a empresas de transporte, hoteles,
                        restaurantes, etc., por consiguiente, en lo que respecta
                        a la responsabilidad por cualquier deficiencia en los
                        servicios prestados, se regirá por lo dispuesto en la
                        Ley 1480 de 2011 o Estatuto del Consumidor. Igualmente,{" "}
                        <strong>Planes Turísticos S.A.S.</strong>, se compromete
                        a cumplir con los servicios de intermediación para los
                        cuales empleará la diligencia debida, propia de una
                        obligación de medio. En todo caso, manifestamos no tener
                        injerencia en las decisiones o políticas de los
                        prestadores de servicios. Cualquier información relativa
                        a impuestos de salida de Colombia y en el exterior,
                        tasas, cargos y demás pagos obligatorios deben ser
                        consultados en las fuentes oficiales.{" "}
                        <strong>Planes Turísticos S.A.S.</strong>, en su calidad
                        de Intermediario, se responsabiliza ante los viajeros
                        por la total prestación y calidad de los servicios
                        descritos en nuestro portal. La responsabilidad por
                        incumplimiento de los prestatarios en la ejecución de
                        sus obligaciones, la ocurrencia de cualquier daño,
                        herida, accidente, retraso o irregularidades durante la
                        ejecución de los servicios, a las personas que efectúen
                        el viaje por su mediación, así como al equipaje y demás
                        objetos de su propiedad; por las consecuencias
                        económicas derivadas de malas condiciones
                        climatológicas, manifestaciones, tumultos, guerras o
                        rumores de guerra, actos terroristas o por cualquier
                        otro motivo que constituya caso fortuito o de fuerza
                        mayor y que por ende no pueda ser imputable a la
                        voluntad de cualquiera de las partes, se regirá por lo
                        dispuesto en la Ley 1480 de 2011. Asimismo, en caso de
                        fuerza mayor o caso fortuito sobreviniente antes o
                        durante el viaje (huelgas, asonadas, terremotos,
                        accidentes, huracanes, avalanchas o cualquier tipo de
                        desastre natural), el prestador del servicio podrá
                        modificar, reemplazar o cancelar itinerarios, fechas,
                        horas, vuelos, hoteles, servicios opcionales con el fin
                        de garantizar el éxito del tour o viaje. Los reembolsos
                        que pudieran presentarse por los servicios no utilizados
                        en caso de fuerza mayor son definidos por cada prestador
                        de servicio y serán informadas en el momento de ocurrir
                        cualquier suceso de fuerza mayor.
                      </p>
                      <br />
                      <p>
                        En caso que el viajero a pesar de haber sido informado
                        no pudiera salir del país por motivos como: presentar
                        pasaporte vencido, permiso de salida del país de menores
                        no tramitados o incompletos, no contar con las vacunas
                        exigidas por cada estado, demandas por alimentos u otro
                        tipo de demandas, homónimos con antecedentes penales
                        ante la justicia colombiana; no será responsabilidad de{" "}
                        <strong>Planes Turísticos S.A.S.</strong>, el no
                        cumplimiento del itinerario previamente pagado. Los
                        reembolsos que se pudiesen presentar por los servicios
                        no prestados en estos casos son definidos por cada
                        prestador de servicio y serán informados en caso de
                        ocurrir cualquiera de los sucesos descritos.
                      </p>
                      <br />
                      <p>
                        Si durante la prestación de los servicios previamente
                        adquiridos por el viajero, este debe retirarse por
                        motivos de índole personal o de salud previamente
                        comprobados, las políticas de reembolso por los
                        servicios no tomados en caso de que existan serán
                        únicamente definidas por el prestador del servicio,
                        debido a que éstas están sujetas a condiciones
                        económicas especiales de contratación o el tipo de
                        servicio contratado.{" "}
                        <strong>Planes Turísticos S.A.S.</strong> y/o las
                        compañías prestatarias de los servicios se reservan el
                        derecho de alterar el itinerario de viaje y los
                        servicios si fuese imprescindible para una mejor
                        organización del mismo o alguna circunstancia de fuerza
                        mayor que lo obligara a ello, en caso que el viajero por
                        deseo expreso accediera a introducir alguna modificación
                        en el itinerario o cambio de hotel para mejorar su
                        categoría deberá pagar la diferencia de los gastos
                        ocasionados por los cambios solicitados, quedando claro
                        que la decisión sobre dichas variaciones son por
                        voluntad propia del viajero, los cambios solicitados
                        están sujetos a disponibilidad.
                      </p>
                      <br />
                      <p>
                        Los reembolsos a que hubiere lugar por algún motivo
                        previamente comprobado se realizarán dentro de los 15
                        días hábiles siguientes a la solicitud, si el trámite
                        toma más tiempo por causas ajenas a{" "}
                        <strong>Planes Turísticos S.A.S.</strong>, ésta no
                        reconocerá ningún interés sobre las sumas a reembolsar.
                      </p>
                      <br />
                      <p>
                        El pasajero es responsable de revisar con las
                        autoridades de los países de destino, los requisitos de
                        ingreso a cada país.{" "}
                        <strong>Planes Turísticos S.A.S.</strong>, prestará
                        asesoría al cliente, pero no será responsable del
                        trámite que realice, ni la respuesta a dichos tramites,
                        siendo de la exclusiva autonomía de las autoridades
                        consulares todo lo relativo al trámite, documentos,
                        tiempo, costos, estudio y aprobación o rechazo del
                        mismo.
                      </p>
                      <br />
                      <p>
                        En caso de negación de la Visa no habrá lugar a
                        reembolso por las sumas pagas por el viajero. Si la Visa
                        otorgada y las autoridades del país de destino no le
                        permiten el ingreso, no existirá responsabilidad por
                        esas decisiones. Las políticas de reembolso por los
                        servicios no tomados en caso que existan, serán
                        únicamente definidas por el prestador de servicio,
                        debido a que éstas están sujetas a condiciones
                        económicas especiales de contratación o el tipo de
                        servicio contratado.
                      </p>
                      <br />
                      <p>
                        Todos los precios de venta que se publican en el portal
                        web, se indican en dólares estadounidenses (USD), con la
                        respectiva Tasa Representativa del Mercado (TRM) del
                        día, la cual podrá ser observada en el aparte
                        “Información de interés” del portal web. Al momento del
                        pago se tendrá en cuenta la TRM del día en que se
                        realice y el portal indicará el valor a pagar en pesos
                        colombianos (COP).
                      </p>
                      <br />
                      <p>
                        Durante el transporte que se utilice en el destino el
                        viajero se somete expresamente a la legislación en
                        materia de accidentes por carretera de la nación en que
                        se halla matriculado el vehículo renunciando
                        taxativamente a cualquier derecho que pudiera
                        corresponderle, en virtud de lo cual las indemnizaciones
                        serán pagadas a los interesados, beneficiarios o sus
                        representantes legales en el país de matrícula del
                        vehículo y precisamente en la moneda legal del mismo. El
                        pago de las posibles indemnizaciones en caso de
                        accidente corresponde únicamente a las compañías de
                        seguros excluyéndose cualquier responsabilidad de
                        terceros tales como compañías aéreas. Marítimas,
                        operadores, etc.
                      </p>
                      <br />
                      <p>
                        Será prerrogativa del operador o del organizador del
                        tour, el retiro de quien, por causa grave de carácter
                        moral o disciplinario debidamente comprobada, atente
                        contra el éxito del mismo, en el evento que faltare
                        servicios por prestar al momento de retiro del viajero,
                        se dará estricta aplicación a las normas establecidas
                        por el prestador del servicio en lo relativo a
                        reembolsos.
                      </p>
                      <br />
                      <p>
                        <strong>Planes Turísticos S.A.S.</strong>, no será
                        responsable si por asuntos Judiciales o de otra índole
                        en que se vea involucrado el viajero en el tour y éste
                        deba retirarse del itinerario en el tour, y mucho menos
                        será responsable por los gastos adicionales en que éste
                        incurra en razón a esas circunstancias. Con relación a
                        los servicios no prestados al momento del retiro del
                        viajero, se aplicarán las políticas de reembolso del
                        operador, si hubiere lugar.
                      </p>
                      <br />
                      <p>
                        Las políticas de restricción, cancelación, penalidades y
                        condiciones particulares de los servicios a prestar
                        serán informadas al viajero en nuestra página web, al
                        momento de realizar su solicitud de pre-reserva. con
                        esta solicitud se entiende que el viajero conoce y
                        acepta todas las políticas de pagos y cancelaciones del
                        itinerario o servicios que está adquiriendo. El viajero
                        o usuario declara que conoce y acepta en su integridad
                        estas condiciones, las cuales constituyen el acuerdo
                        único, total y excluyente de cualquier pacto o
                        disposición legal en contrario, acerca de los términos,
                        condiciones y restricciones de los servicios
                        contratados, de conformidad con las leyes vigentes.
                      </p>
                      <br />
                      <br />
                      <br />
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ height: 50, paddingLeft: 20, paddingRight: 20 }}
                          onClick={() =>
                            window.open(
                              "/contenidos/politicas/CLAUSULARESPONSABILIDAD.pdf",
                              "_blank"
                            )
                          }
                        >
                          IMPRIMIR PDF
                        </Button>
                      </Box>
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
