import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import "../../App.css";
import { Container, Typography, Stack, Grid } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useCallback, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import axios from "axios";
import { AppTitleOrange } from "../../styles/app";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import CommentIcon from "@mui/icons-material/Comment";
import { LoadingButton } from "@mui/lab";
import { AppbarHeaderImage } from "../../styles/appbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";

export default function Circuitsdetaildesktop(props) {
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [nombreApellido, setNombreApellido] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");

  //----------------DIALOG NENSAJE----------------
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagDialogMensaje, setFlagDialogMensaje] = useState(false);
  const [flagCierre, setFlagCierre] = useState(false);
  const [mensajeDialog, setMensajeDialog] = useState("");
  const navigate = useNavigate();

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
      if (flagCierre) handleOnClickHome();
    }
  };

  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    document.title = "Planes Turísticos - PQRS";
  }, []);

  const handleChange = (event) => {
    console.log(event);
    switch (event.target.name) {
      case "tipoSolicitud":
        setTipoSolicitud(event.target.value);
        break;
      case "nombreApellido":
        setNombreApellido(event.target.value);
        break;
      case "celular":
        setCelular(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "mensaje":
        setMensaje(event.target.value);
        break;
      default:
        break;
    }
  };

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  const enviarMensaje = async () => {
    if (!ValidateEmail(email)) {
      setMensajeDialog("Favor indicar el email correctamente.");
      setFlagDialogMensaje(true);
    } else {
      var mensajeRQ = {
        tipoSolicitud: tipoSolicitud,
        nombreApellido: nombreApellido,
        celular: celular,
        email: email,
        mensaje: mensaje,
      };

      var url = "https://iberolunatravel.online/wse/api/PqrsRegistration";
      const resp = await axios.post(url, mensajeRQ, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (resp.data === "OK") {
        setMensajeDialog(
          "Gracias por contactarnos. En breve nos comunicamos con usted."
        );
        setFlagCierre(true);
        setFlagDialogMensaje(true);
      }
    }
  };
  const cuadroContactos = () => {
    let content = [];
    content.push(
      <Stack spacing={2}>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Teléfono
              </Typography>
              <Typography variant="body2" color="text.secondary">
                +57 601 6215010 <br></br>+57 601 2362803
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Línea Nacional
              </Typography>
              <Typography variant="body2" color="text.secondary">
                018000 111 974
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                WhatsApp
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <a
                  aria-label="Chat on WhatsApp"
                  href="whatsapp://send?text=Planes Turísticos&phone=+573112386515&abid=+573112386515"
                >
                  {" "}
                  <img
                    alt="Chat on WhatsApp"
                    src="/images/WhatsAppButtonGreenSmall.svg"
                  />{" "}
                </a>
                <br></br>
                <a href="whatsapp://send?text=Planes Turísticos&phone=+573112386515&abid=+573112386515">
                  +57 320 4117398
                </a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Email
              </Typography>
              <Typography variant="body2" color="text.secondary">
                cliente@iberolunatravel.online
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Dirección
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Carrera 13 # 85 - 39 Ofic. 202 Bogotá - Colombia
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    );
    return content;
  };

  const formulario = () => {
    let content = [];
    content.push(
      <Card>
        <CardContent>
          {/* <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                color: Colors.orange_ibero,
                                fontWeight: "Medium",
                              }}
                            >
                              Consultas sobre su viaje
                            </Typography> */}
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Tipo de solicitud:
            </InputLabel>
            <Select
              id="tipoSolicitud"
              name="tipoSolicitud"
              value={tipoSolicitud}
              onChange={handleChange}
              label="Tipo de solicitud"
              startAdornment={
                <InputAdornment position="start">
                  <AssignmentIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            >
              <MenuItem value={"PQR"}>PQR</MenuItem>
              <MenuItem value={"Retracto"}>Retracto</MenuItem>
              <MenuItem value={"Reversion"}>Reversión de pago</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Nombre y Apellido:
            </InputLabel>
            <Input
              id="nombreApellido"
              name="nombreApellido"
              value={nombreApellido}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Celular:
            </InputLabel>
            <Input
              id="celular"
              name="celular"
              value={celular}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIphoneIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Email:</InputLabel>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <MailIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Mensaje:
            </InputLabel>
            <Input
              id="mensaje"
              name="mensaje"
              multiline
              rows={8}
              value={mensaje}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <CommentIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </CardContent>
        <CardActions></CardActions>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <LoadingButton
            disabled={false}
            variant="contained"
            sx={{
              minWidth: "400px",
              backgroundColor: Colors.orange_ibero,
            }}
            loading={false}
            onClick={enviarMensaje}
          >
            Enviar mensaje
          </LoadingButton>
        </Grid>
      </Card>
    );
    return content;
  };

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth="xl"
          sx={{
            background: "#fff",
            padding: "0px !important",
          }}
        >
          <Stack>
            <UIProvider>
              <Appbar />
              <Stack alignItems="center">
                <AppTitleOrange
                  variant={!props.matches ? "h2" : "h3"}
                  sx={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}
                >
                  PQRS / Retracto / Reversión de Pago
                </AppTitleOrange>
              </Stack>
              <Stack
                sx={{
                  background: "#fff",
                  borderColor: Colors.blue_ibero,
                  padding: "15px",
                  borderRadius: "15px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  m: "15px",
                }}
              >
                <Stack
                  direction={props.matches ? "column" : "row"}
                  divider={
                    <Divider
                      orientation={props.matches ? "horizontal" : "vertical"}
                      flexItem
                    />
                  }
                  spacing={2}
                >
                  {!props.matches ? (
                    <>
                      {cuadroContactos()}
                      {formulario()}
                    </>
                  ) : (
                    <>
                      {formulario()}
                      <Divider
                        sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                      ></Divider>
                      {cuadroContactos()}
                    </>
                  )}
                </Stack>
              </Stack>
              <AppDrawer />
            </UIProvider>
          </Stack>
          <Footer />
        </Container>
      </ThemeProvider>

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/PLanesTuristicos/LogoPLanesTuristicos.png"
            alt="Planes Turísticos"
            title="Planes Turísticos"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
