import { ThemeProvider } from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import Circuits from "./catalogs";

import { AppTitleOrange } from "../../styles/app";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Destinos";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />

            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
               
                  <AppTitleOrange variant={!props.matches ? "h2" : "h3"} sx={{textAlign: "center", marginTop: "30px", marginBottom: "30px"}}>
                    Catálogos de Circuitos
                  </AppTitleOrange>
                  {props.matches ?  <img
                    loading="lazy"
                    width="100%"
                    src={"../../images/IncluyeMovil.jpg"}
                    srcSet={"../../images/IncluyeMovil.jpg"}
                    alt="Incluye Circuito"
                  /> :  <img
                    loading="lazy"
                    width="100%"
                    src={"../../images/Incluye.jpg"}
                    srcSet={"../../images/Incluye.jpg"}
                    alt="Incluye Circuito"
                  />}
                 
                  <br></br>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      m: "15px",
                    }}
                  >
                    <Circuits
                      folletos={props.folletos}
                      matches={props.matches}
                    ></Circuits>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
