import {
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import PagoOpciones from "../pagoOpciones";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../App.css";
import { Container, Typography, Box, Stack, Grid, Button } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import axios from "axios";
import { AppTitleOrange } from "../../styles/app";
import MailIcon from "@mui/icons-material/Mail";
import CommentIcon from "@mui/icons-material/Comment";
import { LoadingButton } from "@mui/lab";
import { AppbarHeaderImage } from "../../styles/appbar";
import SendIcon from "@mui/icons-material/Send";

function Circuitsdetaildesktop(props) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagDialogMensaje, setFlagDialogMensaje] = useState(false);
  const [openPago, setOpenPago] = useState(false);
  const [preReservaCls, setPrereservaCLs] = useState([]);
  const [flag, setFlag] = useState(false);
  const [email, setEmail] = useState("");
  const [prereserva, setPrereserva] = useState("");
  const [loadingBuscar, setLoadingBuscar] = useState(false);
  const [mensajeDialog, setMensajeDialog] = useState("");
  const options = {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0].substring(0, 2)
      : navigator.language.substring(0, 2);

  const optionsDate = {
    timeZone: "America/Bogota", // specify the Colombia time zone
    weekday: "long", // display the full weekday name
    year: "numeric", // display the year (e.g. "2023")
    month: "long", // display the full month name
    day: "numeric", // display the day of the month (e.g. "5")
    hour: "numeric", // display the hour (e.g. "9")
    minute: "numeric", // display the minute (e.g. "30")
    second: "numeric", // display the second (e.g. "15")
    hour12: true, // use 12-hour clock format
  };

  const formatter = new Intl.DateTimeFormat("es", optionsDate);

  useEffect(() => {
    document.title = "Planes Turísticos - Pago de Reserva";
  }, []);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
    }
  };

  const handleChangePreReserva = (event) => {
    setPrereserva(event.target.value);
  };

  const handleCloseDialogPago = () => {
    setOpenPago(false);
  };

  const buscaPreReserva = async () => {
    setLoadingBuscar(true);
    const tokenwb = "inL5(3dEu6n83=";
    const url =
      "https://iberolunatravel.online/wse/api/GetPreReserva?email=" +
      email +
      "&expediente=" +
      prereserva.split("-")[1] +
      "&tokenwb=" +
      tokenwb;
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (
      resp.data !== null &&
      resp.data.estadoEuropamundo !== null &&
      resp.data.expediente !== null
    ) {
      setPrereservaCLs(resp.data);
      switch (resp.data.estadoEuropamundo) {
        case "PRE-RESERVA":
          setFlag(true);
          break;
        case "CANCELADO":
          setMensajeDialog(
            "La Pre-reserva indicada esta cancelada, favor realizarla de nuevo."
          );
          setFlagDialogMensaje(true);
          break;
        case "RESERVA":
          /*  setMensajeDialog("La Pre-reserva esta confirmada.");
          setFlagDialogMensaje(true);
          break; */
          setFlag(true);
          break;
        case "VENCIDA":
          setMensajeDialog("La Pre-reserva indicada está vencida.");
          setFlagDialogMensaje(true);
          break;
        default:
          console.log("");
          break;
      }
    } else {
      setMensajeDialog("No existe la pre-reserva indicada.");
      setFlagDialogMensaje(true);
    }
    setLoadingBuscar(false);
  };

  const ActivaPagoOpciones = () => {
    setOpenPago(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack alignItems="center">
              <br></br>
              <br></br>
              <br></br>
              <AppTitleOrange variant={!props.matches ? "h2" : "h3"}>
                Realice su pago
              </AppTitleOrange>
              <br></br>
              <br></br>
            </Stack>
            <Stack
              sx={{
                background: "#fff",
                borderColor: Colors.blue_ibero,
                padding: "15px",
                borderRadius: "15px",
                borderStyle: "solid",
                borderWidth: "1px",
                m: "15px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction={props.matches ? "column" : "row"}
                  divider={
                    <Divider
                      orientation={props.matches ? "horizontal" : "vertical"}
                      flexItem
                    />
                  }
                  spacing={2}
                >
                  {!props.matches ? (
                    <Stack spacing={2}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardContent>
                            <AppbarHeaderImage src="/images/logo-wompi.svg" />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  ) : (
                    <></>
                  )}

                  <Stack
                    direction="column"
                    spacing={4}
                    sx={{
                      fontWeight: "Medium",
                      width: "100%",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        color: Colors.blue_ibero,
                        fontWeight: "Medium",
                        width: "100%",
                      }}
                    >
                      Indique la información de la Reserva
                    </Typography>

                    <Stack
                      direction={props.matches ? "column" : "row"}
                      spacing={0}
                    >
                      <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">
                          Email del titular:
                        </InputLabel>
                        <Input
                          id="standard-adornment-email"
                          onChange={handleChangeEmail}
                          startAdornment={
                            <InputAdornment position="start">
                              <MailIcon
                                sx={{
                                  color: "action.active",
                                  mr: 1,
                                  my: 0.5,
                                }}
                              />
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">
                          Número de la Pre-reserva:
                        </InputLabel>
                        <Input
                          id="standard-adornment-prereserva"
                          onChange={handleChangePreReserva}
                          startAdornment={
                            <InputAdornment position="start">
                              <CommentIcon
                                sx={{
                                  color: "action.active",
                                  mr: 1,
                                  my: 0.5,
                                }}
                              />
                            </InputAdornment>
                          }
                        />
                        {/*  <Link
                                component="button"
                                variant="BUTTON"
                                color="inherit"
                                onClick={() => {
                                  alert("jkjkj");
                                }}
                              >
                                ¿Olvido su número de Pre-reserva?
                              </Link> */}
                      </FormControl>
                      <LoadingButton
                        disabled={false}
                        variant="contained"
                        onClick={buscaPreReserva}
                        size="small"
                        sx={{
                          minWidth: "200px",
                          backgroundColor: Colors.orange_ibero,
                          maxHeight: "40px",
                          m: 2,
                        }}
                        loading={loadingBuscar}
                      >
                        {/*  <SearchOutlinedIcon
                                  fontSize="60px"
                                  sx={{
                                    color: Colors.white,
                                    marginRight: "5px",
                                  }}
                                />{" "} */}
                        Buscar Pre-Reserva
                      </LoadingButton>
                    </Stack>

                    {flag ? (
                      <Card
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.blue_light,
                        }}
                      >
                        <CardActionArea>
                          <CardContent>
                            <Typography>
                              <Box
                                fontWeight="bold"
                                display="inline"
                                color={Colors.blue_ibero}
                              >
                                Datos de la Pre-Reserva
                              </Box>{" "}
                            </Typography>
                            <List dense={true}>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Número de Pre-Reserva:</b>{" "}
                                  {preReservaCls.expediente}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Nombre del circuito:</b>{" "}
                                  {preReservaCls.nombreCircuito}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Estado:</b>{" "}
                                  {preReservaCls.estadoEuropamundo}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Fecha de inicio del circuito:</b>{" "}
                                  {preReservaCls.fechaInicio}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Cantidad de personas:</b>{" "}
                                  {preReservaCls.cantPersonas}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Monto a pagar en dolares:</b>{" "}
                                  {Number(
                                    preReservaCls.precioTotal
                                  ).toLocaleString(userLocale, options)}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Monto a pagar en COP:</b>{" "}
                                  {Number(
                                    preReservaCls.precioTotalLocal
                                  ).toLocaleString(userLocale, options)}
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <SendIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <b>Fecha caducidad de Pre-Reserva:</b>{" "}
                                  {formatter.format(
                                    Date.parse(
                                      preReservaCls.fechaLimiteColombia
                                    )
                                  )}
                                </ListItemText>
                              </ListItem>
                            </List>

                            <br></br>
                            <br></br>
                            {preReservaCls.estadoEuropamundo ===
                            "PRE-RESERVA" ? (
                              <Box
                                m={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    height: 50,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                  }}
                                  onClick={ActivaPagoOpciones}
                                >
                                  Proceder al Pago
                                </Button>
                              </Box>
                            ) : (
                              <></>
                            )}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Stack>

            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>

      <Dialog
        open={openPago}
        onClose={handleCloseDialogPago}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PagoOpciones
          email={email}
          titular={preReservaCls.titular}
          celular={preReservaCls.celular}
          expediente={preReservaCls.expediente}
          localizadorOnline={preReservaCls.localizadorOnline}
          precioTotalLocal={preReservaCls.precioTotalLocal}
          prereserva={preReservaCls.preReserva}
          cerrarPago={handleCloseDialogPago}
          permiteAbono={preReservaCls.permiteAbono}
        ></PagoOpciones>
        <DialogActions>
          <Button onClick={handleCloseDialogPago} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/PLanesTuristicos/LogoPLanesTuristicos.png"
            alt="Planes Turísticos"
            title="Planes Turísticos"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
