import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import "../../App.css";
import { Container, Typography, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";
import ReactPlayer from "react-player";
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Quiénes somos";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
        <UIProvider>
        <Appbar />          
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
                  <br></br>
                  <br></br>
                  <br></br>
                  <AppTitleOrange variant={!props.matches ? "h2" : "h3"}>
                    Quiénes somos
                  </AppTitleOrange>
                  <br></br>
                  <br></br>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      m: "15px"
                    }}
                  >
                    <ReactPlayer
                      url={"/Images/videos/VIDEOIBEROLUNA.mp4"}
                      width="100%"
                      height="500px"
                      controls={true}
                      playing={true}
                    />
                    <br></br>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: Colors.blue_ibero, fontWeight: "bold" }}
                    >
                      Acerca de Nosotros
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    Expertos en turismo desde hace 30 años, con el apoyo de grandes operadores en los 5 Continentes, las mejores herramientas y equipo altamente capacitado, para dar las opciones de viaje más competitivas del mercado, con mayor respaldo para su tranquilidad y seguridad.
                    </Typography>
                    <br></br>
                    <Typography variant="subtitle1" gutterBottom>
                    Somos una empresa que no vende viajes sino EXPERIENCIAS. Creamos un turismo responsable a través del cual pretendemos transmitir la importancia de valorar y cuidar nuestro entorno, la tierra que nos rodea, distintas culturas, formas diferentes de enfrentarse a la vida. Todo esto gracias a los trabajadores que hacen posible transportar a los clientes un gran valor añadido que es la pasión por viajar.
                    </Typography>
                    <br></br>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: Colors.blue_ibero, fontWeight: "bold" }}
                    >
                      Misión
                    </Typography>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Dar a conocer las novedades y maravillas del mundo
                        viajando con plena garantía y la mejor calidad en cada
                        destino a un precio muy competitivo."
                        />
                      </ListItem>
                    {/*   <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Brindar a los agentes de viajes una información completa
                      en todos los destinos que comercializamos, con una
                      tecnología de vanguardia facilitando la labor y el
                      conocimiento del turismo emisivo."
                        />
                      </ListItem> */}
                    </List>

                    <br></br>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: Colors.blue_ibero, fontWeight: "bold" }}
                    >
                      Descripción
                    </Typography>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Somos líderes en Colombia en el negocio de la tour operación a Europa. Representamos a Europamundo Vacaciones, operador de circuitos en Europa y el mundo. Confeccionamos programas especiales a todo el mundo: Oriente, África y Australia, con ofertas permanentes a Europa, Estados Unidos, Canadá, México, Costa Rica, India y Japón, con 1.972 tours diferentes."
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Comercializamos Salidas Grupales con cupos aéreos garantizados a Europa. Cada año con más de 30 salidas con grupos desde Colombia a precios muy competitivos."
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Forma parte importante de nuestra cultura, asesorar, agilizar y gestionar correctamente las reservas de nuestros clientes."
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <RadioButtonCheckedOutlinedIcon
                            sx={{
                              color: Colors.orange_ibero,
                              fontSize: "22px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Nuestro principal objetivo es fidelizar a nuestros clientes por el buen servicio prestado en destino ofreciendo los mejores programas con los mayores atractivos turísticos, ya que contratamos los servicios con los receptivos más expertos y con una gran experiencia en cada país."
                        />
                      </ListItem>
                    </List>   
                    <br></br>
                    <ReactPlayer
                      url={"https://youtu.be/B0wGQQ9JMUc"}
                      width="100%"
                      height="500px"
                      controls={true}
                    />                
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
