import * as React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../App.css";
import PagoOpciones from "../pagoOpciones";
import { Container, Box, Stack, Button } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import Itinerary from "./itinerary";
import { Colors } from "../../styles/theme";
import Titlebar from "./titlebar";
import Fichacircuitdetail from "./fichacircuitdetail";
import Hotelescircuitsdetail from "./hotelesprevistosdetail";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MailIcon from "@mui/icons-material/Mail";
import CommentIcon from "@mui/icons-material/Comment";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import axios from "axios";
import { AppbarHeaderImage } from "../../styles/appbar";
import { useParams } from "react-router-dom";
import { AppTitleOrange } from "../../styles/app";

function Circuitsdetaildesktop(props) {
  const [optionCircuit, setOptionCircuit] = useState(0);
  const [setCardNumber] = useState(1);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagDialogMensaje, setFlagDialogMensaje] = useState(false);
  const [openPago, setOpenPago] = useState(false);
  const [preReservaCls, setPrereservaCLs] = useState([]);
  const [flag, setFlag] = useState(false);
  const [flagPrimero, setFlagPrimero] = useState(true);
  const [email, setEmail] = useState("");
  const [prereserva, setPrereserva] = useState("");
  const [presupuesto, setPresupuesto] = useState("");
  const [loadingBuscar, setLoadingBuscar] = useState(false);
  const [mensajeDialog, setMensajeDialog] = useState("");
  const [value, setValue] = useState("3");
  const [tipo, setTipo] = useState(props.tipo);
  const params = useParams();
  //const tipo = params.tipo;
  const emailconsulta = params.email;
  const identificador = params.identificador;

  const options = {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0].substring(0, 2)
      : navigator.language.substring(0, 2);

  const optionsDate = {
    timeZone: "America/Bogota", // specify the Colombia time zone
    weekday: "long", // display the full weekday name
    year: "numeric", // display the year (e.g. "2023")
    month: "long", // display the full month name
    day: "numeric", // display the day of the month (e.g. "5")
    hour: "numeric", // display the hour (e.g. "9")
    minute: "numeric", // display the minute (e.g. "30")
    second: "numeric", // display the second (e.g. "15")
    hour12: true, // use 12-hour clock format
  };

  const optionsDateNoTime = {
    timeZone: "America/Bogota", // specify the Colombia time zone
    weekday: "long", // display the full weekday name
    year: "numeric", // display the year (e.g. "2023")
    month: "long", // display the full month name
    day: "numeric", // display the day of the month (e.g. "5")
  };

  const formatter = new Intl.DateTimeFormat("es", optionsDate);
  const formatterNoTime = new Intl.DateTimeFormat("es", optionsDateNoTime);

  useEffect(() => {
    console.log(props.tipo);
    if (flagPrimero && email !== "*" && identificador !== "*") {
      window.scrollTo(0, 0);

      setEmail(emailconsulta);
      if (props.tipo.toLowerCase() === "presupuesto") {
        setPresupuesto(identificador);
        buscarPresupuesto();
      } else {
        setPrereserva(identificador);
        buscaPreReserva();
      }
    }
  }, []);

  const setOption = (option) => {
    setOptionCircuit(option);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
    }
  };

  const handleChangePreReserva = (event) => {
    setPrereserva(event.target.value);
  };

  const handleChangePresupuesto = (event) => {
    setPresupuesto(event.target.value);
  };

  const handleCloseDialogPago = () => {
    setOpenPago(false);
  };

  const buscaPreReserva = async () => {
    setLoadingBuscar(true);
    setFlag(false);

    var emailP = "";
    var expedienteP = "";
    if (flagPrimero && email !== "*" && identificador !== "*") {
      emailP = emailconsulta;
      expedienteP = identificador.split("-")[1];
    } else {
      emailP = email;
      expedienteP = prereserva.split("-")[1];
    }
    const tokenwb = "inL5(3dEu6n83=";
    const url =
      "https://iberolunatravel.online/wse/api/GetPreReserva?email=" +
      emailP +
      "&expediente=" +
      expedienteP +
      "&tokenwb=" +
      tokenwb;
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (
      resp.data !== null &&
      resp.data.estadoEuropamundo !== null &&
      resp.data.expediente !== null
    ) {
      props.fillData(
        resp.data.codigoCircuitoEuropamundo,
        resp.data.itinerarios
      );
      setPrereservaCLs(resp.data);
      switch (resp.data.estadoEuropamundo) {
        case "PRE-RESERVA":
          setFlag(true);
          setValue("3");

          break;
        case "CANCELADO":
          setMensajeDialog(
            "La Pre-reserva indicada esta cancelada, favor realizarla de nuevo."
          );
          setFlagDialogMensaje(true);
          break;
        case "RESERVA":
          /*           setMensajeDialog("La Pre-reserva esta confirmada.");
          setFlagDialogMensaje(true);
          break; */
          setFlag(true);
          setValue("3");
          break;
        case "VENCIDA":
          setMensajeDialog("La Pre-reserva indicada está vencida.");
          setFlagDialogMensaje(true);
          break;
        default:
          console.log("");
          break;
      }
      // SE DEBE ELIMIANAR
      setFlag(true);
      setValue("3");
      // SE DEBE ELIMIANAR
    } else {
      setMensajeDialog("No existe la pre-reserva indicada.");
      setFlagDialogMensaje(true);
    }
    setLoadingBuscar(false);
    setFlagPrimero(false);
  };

  const buscarPresupuesto = async () => {
    setLoadingBuscar(true);
    setFlag(false);

    var emailP = "";
    var expedienteP = "";
    if (flagPrimero && email !== "*" && identificador !== "*") {
      emailP = emailconsulta;
      expedienteP = identificador;
    } else {
      emailP = email;
      expedienteP = presupuesto;
    }
    const tokenwb = "inL5(3dEu6n83=";
    const url =
      "https://iberolunatravel.online/wse/api/GetPresupuesto?email=" +
      emailP +
      "&codPresupuesto=" +
      expedienteP +
      "&tokenwb=" +
      tokenwb;
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (resp.data !== null && resp.data.estadoEuropamundo !== null) {
      setPrereservaCLs(resp.data);
      switch (resp.data.estadoEuropamundo) {
        case "Activo":
          setFlag(true);
          setValue("3");
          props.fillData(
            resp.data.codigoCircuitoEuropamundo,
            resp.data.itinerarios
          );
          break;
        case "No Existe":
          setMensajeDialog("El presupuesto indicado no existe.");
          setFlagDialogMensaje(true);
          break;
        case "Vencid0":
          setMensajeDialog("El presupuesto indicado esta vencido.");
          setFlagDialogMensaje(true);
          break;
        default:
          console.log("");
          break;
      }
    } else {
      setMensajeDialog("No existe el presupuesto indicado.");
      setFlagDialogMensaje(true);
    }
    setLoadingBuscar(false);
    setFlagPrimero(false);
  };

  const ActivaPagoOpciones = () => {
    setOpenPago(true);
  };

  const ActualizaCardNumber = (cardN) => {
    setCardNumber(cardN);
    //console.log(cardN);
  };

  const detalleReserva = () => {
    let content = [];
    content.push(
      <>
        <Card
          sx={{
            width: "100%",
            backgroundColor:
              tipo.toUpperCase() === "PRESUPUESTO"
                ? Colors.orange_light
                : Colors.blue_light,
          }}
        >
          <CardActionArea>
            <CardContent
              sx={{
                fontSize: "18px",
              }}
            >
              {/*  <Typography>
                <Box
                  fontWeight="bold"
                  display="inline"
                  color={Colors.blue_ibero}
                >
                  Datos de la Pre-Reserva
                </Box>{" "}
              </Typography> */}
              <List dense={true}>
                {/* <ListItem>
                  <ListItemText>
                    <b>Número de Pre-Reserva:</b> {preReservaCls.expediente}
                  </ListItemText>
                </ListItem> */}
                {/*  <ListItem>
                  <ListItemText>
                    <b>Nombre del circuito:</b> {preReservaCls.nombreCircuito}
                  </ListItemText>
                </ListItem> */}
                <ListItem>
                  <ListItemText>
                    <b>Estado:</b> {preReservaCls.estadoEuropamundo}
                  </ListItemText>
                </ListItem>
                {tipo.toUpperCase() === "PRESUPUESTO" ? (
                  <>
                    <ListItem>
                      <ListItemText>
                        <b>Noches Adicionales al Inicio:</b>{" "}
                        {preReservaCls.nochesAdicionalesInicio}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <b>Noches Adicionales al Final:</b>{" "}
                        {preReservaCls.nochesAdicionalesFin}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <b>Traslado de Llegada:</b>{" "}
                        {preReservaCls.trasladoLlegada ? "Si" : "No"}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <b>Traslado de Salida:</b>{" "}
                        {preReservaCls.trasladoSalida ? "Si" : "No"}
                      </ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <></>
                )}

                <ListItem>
                  <ListItemText>
                    <b>Fecha de inicio del circuito:</b>{" "}
                    {tipo.toUpperCase() === "PRESUPUESTO" ? (
                      <>
                        {formatterNoTime.format(
                          Date.parse(preReservaCls.fechaInicio)
                        )}
                      </>
                    ) : (
                      <>{preReservaCls.fechaInicio}</>
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Cantidad de personas:</b> {preReservaCls.cantPersonas}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Monto a pagar en dolares:</b>{" "}
                    {Number(preReservaCls.precioTotal).toLocaleString(
                      userLocale,
                      options
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Monto a pagar en COP a la TRM del día:</b>{" "}
                    {Number(preReservaCls.precioTotalLocal).toLocaleString(
                      userLocale,
                      options
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    {tipo.toUpperCase() === "PRESUPUESTO" ? (
                      <>
                        <b>Fecha caducidad del Presupuesto:</b>{" "}
                      </>
                    ) : (
                      <>
                        <b>Fecha caducidad de Pre-Reserva:</b>{" "}
                      </>
                    )}
                    {formatter.format(
                      Date.parse(preReservaCls.fechaLimiteColombia)
                    )}
                  </ListItemText>
                </ListItem>
              </List>
              <br></br>
              <Typography sx={{ color: Colors.danger }}>
                El precio del traslado de llegada, puede sufrir ajustes
                dependiendo del aeropuerto y la hora de llegada de los vuelos
                que usted reserve.
              </Typography>
              <br></br>
              <Typography sx={{ color: Colors.danger }}>
                El traslado de salida no esta incluido.
              </Typography>
              <br></br>
              <br></br>
              {preReservaCls.estadoEuropamundo === "PRE-RESERVA" ? (
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: 50,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    onClick={ActivaPagoOpciones}
                  >
                    Proceder al Pago
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
    return content;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*   const handleChange = (event, newValue) => {
    setValue(newValue);
  }; */

  useEffect(() => {
    //setOptionCircuit(option)
    // console.log(optionCircuit);
  }, [optionCircuit]);

  useEffect(() => {
    document.title = "Planes Turísticos - Consulta";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack alignItems="center">
              <AppTitleOrange
                variant={!props.matches ? "h2" : "h4"}
                sx={{
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                {tipo.toUpperCase() === "RESERVA"
                  ? "Consulta de Reserva"
                  : "Consulta del Presupuesto"}
              </AppTitleOrange>
            </Stack>
            {tipo.toUpperCase() === "RESERVA" ? (
              <Stack
                sx={
                  !props.matches
                    ? {
                        background: "#fff",
                        borderColor: Colors.blue_ibero,
                        padding: "5px",
                        borderRadius: "15px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        m: "15px",
                      }
                    : {
                        background: "#fff",
                        padding: "0px",
                        borderStyle: "none",
                        m: "5px",
                      }
                }
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: Colors.blue_ibero,
                    fontWeight: "Medium",
                    width: "100%",
                    paddingTop: "20px",
                    paddingLeft: "10px",
                  }}
                >
                  Indique la información de la Pre-reserva
                </Typography>

                <Stack direction={props.matches ? "column" : "row"} spacing={0}>
                  <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                      Email del titular:
                    </InputLabel>
                    <Input
                      id="standard-adornment-email"
                      onChange={handleChangeEmail}
                      value={email}
                      startAdornment={
                        <InputAdornment position="start">
                          <MailIcon
                            sx={{
                              color: "action.active",
                              mr: 1,
                              my: 0.5,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                      Número de la Pre-reserva:
                    </InputLabel>
                    <Input
                      id="standard-adornment-prereserva"
                      onChange={handleChangePreReserva}
                      value={prereserva}
                      startAdornment={
                        <InputAdornment position="start">
                          <CommentIcon
                            sx={{
                              color: "action.active",
                              mr: 1,
                              my: 0.5,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    {/*  <Link
                                component="button"
                                variant="BUTTON"
                                color="inherit"
                                onClick={() => {
                                  alert("jkjkj");
                                }}
                              >
                                ¿Olvido su número de Pre-reserva?
                              </Link> */}
                  </FormControl>
                  <LoadingButton
                    disabled={false}
                    variant="contained"
                    onClick={buscaPreReserva}
                    size="small"
                    sx={{
                      minWidth: "200px",
                      backgroundColor: Colors.orange_ibero,
                      maxHeight: "40px",
                      m: 2,
                    }}
                    loading={loadingBuscar}
                  >
                    {/*  <SearchOutlinedIcon
                                  fontSize="60px"
                                  sx={{
                                    color: Colors.white,
                                    marginRight: "5px",
                                  }}
                                />{" "} */}
                    Buscar Pre-Reserva
                  </LoadingButton>
                </Stack>

                {flag ? (
                  <>
                    <Fragment>
                      {/*  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  > */}
                      {/*  <Box sx={{ width: "160px" }}>
                      <Informationcircuitsdetail
                        onClick={setOption}
                        pdf={props.circuitoData.PDFDOLARES}
                        word={props.circuitoData.WORDDOLARES}
                      />
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem /> */}
                      <br></br>
                      <Divider></Divider>
                      <br></br>
                      <Titlebar
                        circuitoData={props.circuitoData}
                        onClick={setOption}
                        matches={props.matches}
                        flagAction={false}
                      ></Titlebar>
                      {/* <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="1"> <Fragment>
                            <Fichacircuitdetail
                              imagenes={props.imagenes}
                              circuitoIncluye={props.circuitoIncluye}
                              imagenmapa={props.circuitoData.IMAGENMAPA}
                              circuito={props.circuitoData}
                              matches={props.matches}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            ></Fichacircuitdetail>

                            <Itinerary
                              itinerario={props.itinerario}
                              matches={props.matches}
                              tipo={"DETALLADO"}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            />
                          </Fragment></Tab>
        <Tab value="2">
        <Hotelescircuitsdetail
                            hoteles={props.hoteles}
                          ></Hotelescircuitsdetail>
        </Tab>
        <Tab value="3">{detalleReserva()}</Tab>
      </Tabs> */}

                      <TabContext value={value}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            color: Colors.orange_ibero,
                          }}
                        >
                          <TabList
                            onChange={handleChange}
                            sx={{
                              color: Colors.orange_ibero,
                            }}
                          >
                            <Tab label="Detalle de Reserva" value="3" />
                            <Tab label="Detalle de Circuito" value="1" />
                            <Tab label="Hoteles Previstos" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <Fragment>
                            <Fichacircuitdetail
                              imagenes={props.imagenes}
                              circuitoIncluye={props.circuitoIncluye}
                              imagenmapa={props.circuitoData.IMAGENMAPA}
                              circuito={props.circuitoData}
                              matches={props.matches}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            ></Fichacircuitdetail>

                            <Itinerary
                              itinerario={props.itinerario}
                              matches={props.matches}
                              tipo={"DETALLADO"}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            />
                          </Fragment>
                        </TabPanel>
                        <TabPanel value="2">
                          {" "}
                          <Hotelescircuitsdetail
                            hoteles={props.hoteles}
                          ></Hotelescircuitsdetail>
                        </TabPanel>
                        <TabPanel value="3">{detalleReserva()}</TabPanel>
                      </TabContext>

                      {/* </Stack> */}
                    </Fragment>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            ) : (
              <Stack
                sx={
                  !props.matches
                    ? {
                        background: "#fff",
                        borderColor: Colors.blue_ibero,
                        padding: "5px",
                        borderRadius: "15px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        m: "15px",
                      }
                    : {
                        background: "#fff",
                        padding: "0px",
                        borderStyle: "none",
                        m: "5px",
                      }
                }
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: Colors.blue_ibero,
                    fontWeight: "Medium",
                    width: "100%",
                    paddingTop: "20px",
                    paddingLeft: "10px",
                  }}
                >
                  Indique la información del presupuesto
                </Typography>

                <Stack direction={props.matches ? "column" : "row"} spacing={0}>
                  <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                      Email del titular:
                    </InputLabel>
                    <Input
                      id="standard-adornment-email"
                      onChange={handleChangeEmail}
                      value={email}
                      startAdornment={
                        <InputAdornment position="start">
                          <MailIcon
                            sx={{
                              color: "action.active",
                              mr: 1,
                              my: 0.5,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 2 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                      Número del presupuesto:
                    </InputLabel>
                    <Input
                      id="standard-adornment-prereserva"
                      onChange={handleChangePresupuesto}
                      value={presupuesto}
                      startAdornment={
                        <InputAdornment position="start">
                          <CommentIcon
                            sx={{
                              color: "action.active",
                              mr: 1,
                              my: 0.5,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <LoadingButton
                    disabled={false}
                    variant="contained"
                    onClick={buscarPresupuesto}
                    size="small"
                    sx={{
                      minWidth: "200px",
                      backgroundColor: Colors.orange_ibero,
                      maxHeight: "40px",
                      m: 2,
                    }}
                    loading={loadingBuscar}
                  >
                    {/*  <SearchOutlinedIcon
                                  fontSize="60px"
                                  sx={{
                                    color: Colors.white,
                                    marginRight: "5px",
                                  }}
                                />{" "} */}
                    Buscar Presupuesto
                  </LoadingButton>
                </Stack>

                {flag ? (
                  <>
                    <Fragment>
                      <br></br>
                      <Divider></Divider>
                      <br></br>
                      <Titlebar
                        circuitoData={props.circuitoData}
                        onClick={setOption}
                        matches={props.matches}
                        flagAction={false}
                      ></Titlebar>
                      <TabContext value={value}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            color: Colors.orange_ibero,
                          }}
                        >
                          <TabList
                            onChange={handleChange}
                            sx={{
                              color: Colors.orange_ibero,
                            }}
                          >
                            <Tab label="Detalle de Reserva" value="3" />
                            <Tab label="Detalle de Circuito" value="1" />
                            <Tab label="Hoteles Previstos" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <Fragment>
                            <Fichacircuitdetail
                              imagenes={props.imagenes}
                              circuitoIncluye={props.circuitoIncluye}
                              imagenmapa={props.circuitoData.IMAGENMAPA}
                              circuito={props.circuitoData}
                              matches={props.matches}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            ></Fichacircuitdetail>

                            <Itinerary
                              itinerario={props.itinerario}
                              matches={props.matches}
                              tipo={"DETALLADO"}
                              flagWait={props.flagWait}
                              flagDialog={false}
                            />
                          </Fragment>
                        </TabPanel>
                        <TabPanel value="2">
                          {" "}
                          <Hotelescircuitsdetail
                            hoteles={props.hoteles}
                          ></Hotelescircuitsdetail>
                        </TabPanel>
                        <TabPanel value="3">{detalleReserva()}</TabPanel>
                      </TabContext>

                      {/* </Stack> */}
                    </Fragment>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            )}
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
        {/*
        AppBar
        Promotions
        creteriabox
        Products
        footer
        Searchbos
        appDrawer
        */}
      </Container>

      <Dialog
        open={openPago}
        onClose={handleCloseDialogPago}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PagoOpciones
          email={email}
          titular={preReservaCls.titular}
          celular={preReservaCls.celular}
          expediente={preReservaCls.expediente}
          localizadorOnline={preReservaCls.localizadorOnline}
          precioTotalLocal={preReservaCls.precioTotalLocal}
          prereserva={preReservaCls.preReserva}
          cerrarPago={handleCloseDialogPago}
          permiteAbono={preReservaCls.permiteAbono}
        ></PagoOpciones>
        <DialogActions>
          <Button onClick={handleCloseDialogPago} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/PLanesTuristicos/LogoPLanesTuristicos.png"
            alt="Planes Turísticos"
            title="Planes Turísticos"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
