import { ThemeProvider } from "@mui/material";
import "../../App.css";
import { Container, Box, Stack, Button } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Tratamiento de Datos";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="center"
              >
                <Stack alignItems="center" justifyContent="center">
                  <AppTitleOrange variant={!props.matches ? "h4" : "h5"} sx={{marginTop: "30px", marginBottom: "30px"}}>
                    POLÍTICAS DE TRATAMIENTO DE DATOS PERSONALES
                  </AppTitleOrange>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      m: "15px",
                    }}
                  >
                    <div style={{ textAlign: "justify" }}>
                      <p>
                        <strong>AVISO DE PRIVACIDAD.</strong>
                      </p>
                      <br />
                      <p>
                        En cumplimiento de la Ley 1581 de 2012 y demás normas
                        vigentes, Planes Turísticos S.A.S., identificada con NIT
                        No. 830.008.421-5, con domicilio en la ciudad de Bogotá
                        D.C. y como responsable del tratamiento de datos
                        personales, informa a todos los interesados las
                        políticas adoptadas en cuanto a la recolección, uso,
                        almacenamiento, circulación y supresión de los datos
                        personales otorgados por los titulares en relación con
                        la prestación de nuestros servicios.
                      </p>
                      <br />
                      <p>
                        <strong> 1. RECOLECCIÓN.</strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S. se limitará a la recolección de
                        los datos personales necesarios y adecuados para los
                        fines descritos en la presente política y conforme a la
                        normatividad vigente, para lo cual usted otorgará su
                        consentimiento expreso. Por lo que le informamos que
                        recopilaremos los siguientes datos personales:
                      </p>
                      <ul>
                        <li>Nombres y Apellidos</li>
                        <li>Fecha de nacimiento</li>
                        <li>Pasaporte</li>
                        <li>Nacionalidad</li>
                        <li>Sexo</li>
                        <li>Celular</li>
                        <li>Correo electrónico</li>
                      </ul>
                      <br />
                      <p>
                        Los datos personales serán recolectados directamente por
                        Planes Turísticos S.A.S., a través de los siguientes
                        medios: del formulario de solicitud de pre-reserva en
                        nuestra página web. Y los datos suministrados
                        posteriormente via correo electrónico.
                      </p>
                      <br />
                      <p>
                        <strong> 2. USO DE LOS DATOS PERSONALES</strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S., recolectará los datos
                        personales directamente de su titular, previa
                        autorización, con el fin de ser utilizados
                        exclusivamente para los siguientes fines:
                      </p>
                      <ul>
                        <li>Proceso de reserva y confirmación de reserva</li>
                        <li>Prestación de los servicios contratados</li>
                        <li>Envio de material publicitario</li>
                        <li>Cumplimiento de obligaciones legales</li>
                      </ul>
                      <br />
                      <p>
                        Nos comprometemos a no utilizar los datos personales
                        recolectados para fines diferentes a los descritos en
                        este punto, sin previa autorización del Titular.
                      </p>

                      <br />
                      <p>
                        <strong>
                          {" "}
                          3. ALMACENAMIENTO DE LOS DATOS PERSONALES.
                        </strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S., almacenará los datos personales
                        recolectados durante el tiempo necesario para cumplir
                        con los fines descritos en el punto anterior, de
                        requerimientos de autoridades competentes y obligaciones
                        legales. Durante su almacenamiento, se compromete a
                        adoptar las medidas necesarias para su protección. Los
                        datos se almacenarán en nuestra base de datos back
                        office.
                      </p>
                      <br />
                      <p>
                        <strong> 4. CIRCULACIÓN DE DATOS PERSONALES.</strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S., compartirá los datos personales
                        recolectados con terceros, previo consentimiento expreso
                        del Titular, con autoridades competentes, en caso de ser
                        requerido por la ley u orden judicial. Y en el normal
                        manejo para el desarrollo de las funciones específicas,
                        para el cumplimiento de la prestación de servicios con
                        nuestros aliados operacionales.
                      </p>
                      <br />
                      <p>
                        <strong> 5. SUPRESIÓN.</strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S., compartirá los datos personales
                        recolectados durante el tiempo necesario para cumplir
                        con los fines descritos en la presente política o para
                        el cumplimiento de requerimientos de autoridades
                        competentes u obligaciones legales, una vez cumplida la
                        finalidad según sea el caso, o presentada la solicitud
                        de supresión, procederemos a la supresión de los datos
                        personales. Puede solicitar la actualización,
                        rectificación y/o supresión de sus datos personales, a
                        través de nuestra pagina web, por medio del formulario
                        de PQR, como una petición.
                      </p>
                      <br />
                      <p>
                        <strong> 6. DERECHOS DE LOS TITULARES.</strong>
                      </p>
                      <p>
                        Como titular de los datos personales recolectados por
                        Planes Turísticos S.A.S., usted tiene derecho a la
                        intimidad personal y familiar, así como a conocer,
                        actualizar y rectificar la información personal que
                        poseemos; Además se le informa que usted puede otorgar
                        la autorización para el tratamiento de sus datos
                        personales, la cual debe ser previa e informada y que
                        tiene derecho a solicitar pruebas de dicha autorización.
                      </p>
                      <br />
                      <p>
                        Asimismo, nos comprometemos a informarle previamente del
                        tratamiento y uso que haremos con su información
                        personal y que tiene derecho a presentar ante la
                        Superintendencia de Industria y Comercio quejas por
                        infracciones a la Ley 1581 de 2015 y demás normas que la
                        modifiquen, adicionen o complemente.
                      </p>
                      <br />
                      <p>
                        Usted puede revocar la autorización otorgada y/o
                        solicitar la supresión de la información personal en los
                        términos del artículo 8 de la Ley 1581 de 2015 y puede
                        acceder de forma gratuita a sus datos personales que
                        hayan sido objeto de tratamiento.
                      </p>
                      <br />
                      <p>
                        La autorización para el tratamiento de datos se
                        entenderá otorgada cuando se acepten las presentes
                        políticas, al momento de la solicitud de pre-reserva por
                        medio de nuestra pagina web, o al solicitarlo via correo
                        electrónico al aceptar la reserva.
                      </p>
                      <br />
                      <p>
                        <strong> 7. CANALES DE ATENCIÓN.</strong>
                      </p>
                      <p>
                        El responsable del tratamiento de los datos personales
                        es Planes Turísticos S.A.S.,
                      </p>
                      <p>
                        <strong>PBX:</strong> (601) 2362803
                      </p>
                      <p>
                        <strong>Línea Nacional:</strong> 018000111974
                      </p>
                      <p>
                        <strong>Dirección:</strong> Cra. 13 #85-39 of 202,
                        Localidad de Chapinero, Bogotá
                      </p>
                      <p>
                        <strong>Email:</strong> clientes@iberolunatravel.online
                      </p>
                      <br />
                      <p sx={{ textAlign: "center" }}>
                        <strong>
                          {" "}
                          a. Procedimiento de atención de peticiones.
                        </strong>
                      </p>
                      <p>
                        Para ejercer sus derechos como titular de los datos
                        personales, presentar solicitudes o inquietudes sobre
                        nuestras políticas de tratamiento de datos personales,
                        por favor, diligencie el formato de PQR en nuestra
                        página web, con su solicitud, suministre el correo
                        electrónico donde desea recibir respuesta.
                      </p>
                      <br />
                      <p>
                        <strong> 8. VIGENCIA.</strong>
                      </p>
                      <p>
                        Las presentes políticas de tratamiento de datos
                        personales tendrán vigencia a partir de la publicación
                        de la misma.
                      </p>
                      <br />
                      <p sx={{ textAlign: "center" }}>
                        <strong>
                          {" "}
                          a. Cambios en las políticas de tratamiento de datos
                          personales.
                        </strong>
                      </p>
                      <p>
                        Planes Turísticos S.A.S., se reserva el derecho de
                        modificar las presentes políticas de tratamiento de
                        datos personales en cualquier momento, de acuerdo a la
                        normatividad vigente. En caso de modificación,
                        informaremos a los titulares mediante medio de
                        comunicación idóneo. En todo caso, los titulares pueden
                        consultar las políticas actualizadas en el portal web:
                        www.iberolunatravel.online
                      </p>
                      <br/>
                      <p>
                      Fecha de publicación: 15 de abril de 2023
                      </p>
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ height: 50, paddingLeft: 20, paddingRight: 20 }}
                          onClick={() =>
                            window.open(
                              "/contenidos/politicas/POLITICATRATAMIENTODATOSPERSONALES.pdf",
                              "_blank"
                            )
                          }
                        >
                          IMPRIMIR PDF
                        </Button>
                      </Box>
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
