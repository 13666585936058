import { ThemeProvider, useMediaQuery } from "@mui/material";
import "./App.css";
import { Container } from "@mui/material";
import theme, { Colors } from "./styles/theme";
import { Fragment, useEffect } from "react";
import Home from "./components/home";
import Circuitsdetail from "./components/circuitsdetail";
import Circuitsresults from "./components/circuitsresults";
import Quienessomos from "./components/quienessomos";
import Catalogs from "./components/catalogs";
import Contacto from "./components/contacto";
import Pqrs from "./components/pqrs";
import Salidasgrupales from "./components/salidasgrupales";
import Pago from "./components/pago";
import Condiciones from "./components/condicionesgenerales";
import Privacidad from "./components/politicasprivacidad";
import Clausula from "./components/clausularesponsabilidad";
import Politicas from "./components/politicasprivacidad";
import TratamientoDatos from "./components/tratamientodatos";
import Resultadotransaccion from "./components/resultadotransaccion";
import Programasmasvendidos from "./components/ProgramasEspeciales";
//import ConsultaReserva from "./components/consultaReserva"
import ConsultaReserva from "./components/circuitsquery";
import ConsultaPresupuesto from "./components/circuitsquerypresupuesto";
import Europamundo from "./components/europamundo";
import Asistente from "./components/asistente";
import EuropaTodos from "./components/landingPages/onepirate/Home";
import CookieConsent from "react-cookie-consent";
import EuropaTodos2024 from "./components/landingPages/EuropaTodos/Home";
import EuropaAlcance from "./components/landingPages/EuropaAlcance/Home";
import EspanaPortugalMarruecos from "./components/landingPages/EspanaPortugalMarruecos/Home";
import EuropaRomantica from "./components/landingPages/EuropaRomantica/Home";
import EuropaCompletisima from "./components/landingPages/EuropaCompletisima/Home";
import IberoOfertaEuropa from "./components/landingPages/IberoOfertaEuropa/Home";
import {
  BrowserRouter,
  HashRouter,
  Link,
  Route,
  Routes,
  StaticRouter,
  useLocation,
} from "react-router-dom";
//import { ReactComponent as CompanyIcon } from '../public/images/PLanesTuristicos/LogoPLanesTuristicos.png';
import { ReactComponent as CompanyIcon } from "./iberoluna.svg";

import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { useState } from "react";

function App() {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Fragment>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <Container
            maxWidth="xl"
            sx={{
              background: "#fff",
              padding: "0px !important",
            }}
          >
            <Routes>
            <Route
                path="/EuropaCompletisima"
                caseSensitive={false}
                element={<EuropaCompletisima />}
              />
              <Route
                path="/europatodos"
                caseSensitive={false}
                element={<EuropaTodos />}
              />
                <Route
                path="/europatodos2024"
                caseSensitive={false}
                element={<EuropaTodos2024 />}
              />
              <Route
                path="/europaromantica"
                caseSensitive={false}
                element={<EuropaRomantica />}
              />
              <Route
                path="/europaalcance"
                caseSensitive={false}
                element={<EuropaAlcance />}
              />
              <Route
                path="/espanaportugalmarruecos"
                caseSensitive={false}
                element={<EspanaPortugalMarruecos />}
              />
              <Route
                path="/iberoofertaeuropa"
                caseSensitive={false}
                element={<IberoOfertaEuropa />}
              />
              <Route
                path="/asistente"
                caseSensitive={false}
                element={<Asistente />}
              />
                <Route
                path="/asistente"
                caseSensitive={false}
                element={<Asistente />}
              />
              <Route
                path="/europamundo"
                caseSensitive={false}
                element={<Europamundo />}
              />
              <Route
                path="/consultareserva/:email/:identificador"
                caseSensitive={false}
                element={<ConsultaReserva tipo={"reserva"} />}
              />
              <Route
                path="/consultapresupuesto/:email/:identificador"
                caseSensitive={false}
                element={<ConsultaPresupuesto tipo={"presupuesto"} />}
              />
              <Route
                path="/privacidad"
                caseSensitive={false}
                element={<Privacidad />}
              />
              <Route
                path="/politicas"
                caseSensitive={false}
                element={<Politicas />}
              />
              <Route
                path="/clausula"
                caseSensitive={false}
                element={<Clausula />}
              />
              <Route
                path="/tratamientodatos"
                caseSensitive={false}
                element={<TratamientoDatos />}
              />
              <Route
                path="/condiciones"
                caseSensitive={false}
                element={<Condiciones />}
              />
              <Route
                path="/contacto"
                caseSensitive={false}
                element={<Contacto />}
              />
              <Route path="/pqrs" caseSensitive={false} element={<Pqrs />} />
              <Route path="/pago" caseSensitive={false} element={<Pago />} />
              <Route
                path="/quienessomos"
                caseSensitive={false}
                element={<Quienessomos />}
              />
              <Route
                path="/circuito/:idviaje/:fecha"
                caseSensitive={false}
                element={<Circuitsdetail tipo={"Circuito"} />}
              />
              <Route
                path="/oferta/:idviaje/:fecha"
                caseSensitive={false}
                element={<Circuitsdetail tipo={"Oferta"} />}
              />
              <Route
                path="/catalogos/"
                caseSensitive={false}
                element={<Catalogs />}
              />
              <Route
                path="/resultadotransaccion"
                caseSensitive={false}
                element={<Resultadotransaccion />}
              />
              <Route
                path="/masvendidos"
                caseSensitive={false}
                element={<Salidasgrupales />}
              />
              {/*       <Route
                path="/consultareserva"
                caseSensitive={false}
                element={<ConsultaReserva />}
              /> */}
              <Route
                path="/results/:tipo/:folleto"
                caseSensitive={false}
                element={<Circuitsresults />}
              />
              <Route
                path="/programasmasvendidos/:url"
                caseSensitive={false}
                element={<Programasmasvendidos />}
              />
              <Route
                path="/results/:tipo/:folleto/:fecha/:paises/:ciudades/:dias"
                caseSensitive={false}
                element={<Circuitsresults />}
              />
              <Route path="/home" caseSensitive={false} element={<Home />} />
              <Route
                path="/home/:opcion"
                caseSensitive={false}
                element={<Home />}
              />
              {/* <Route path="/homenueva" caseSensitive={false} element={<Home opcion={"nueva"}/>} /> */}
              <Route path="/" caseSensitive={false} element={<Home />} />
            </Routes>
          </Container>
        </ThemeProvider>
      </HashRouter>
      <CookieConsent
        location="bottom"
        buttonText="ACEPTAR TODO"
        style={{
          background: Colors.blue_ibero,
          height: matches ? "200px" : "100px",
        }}
        buttonStyle={{ color: "black", fontSize: "16px" }}
        expires={30}
      >
        Utilizamos cookies para mejorar su experiencia de navegación, publicar
        anuncios o contenidos personalizados y analizar nuestro tráfico.
        <p>
          Al hacer clic en "Aceptar todo" o continúas navegando, acepta nuestro
          uso de cookies.
        </p>
      </CookieConsent>
      <WhatsAppWidget
        companyName="Planes Turísticos"
        message="Buenas, ¿Dónde desea viajar?"
        replyTimeText=""
        sendButtonText="Enviar mensaje"
        inputPlaceHolder=""
        //CompanyIcon={CompanyIcon}
        phoneNumber="+573204434444"
      />
    </Fragment>
  );
}

export default App;
