import {
  ThemeProvider,
} from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import {  useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Políticas de Tratamiento";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
        <UIProvider>
        <Appbar />                      
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
                  <br></br>
                  <br></br>
                  <br></br>
                  <AppTitleOrange variant={!props.matches ? "h2" : "h3"}>
                    Cláusula de Responsabilidad
                  </AppTitleOrange>
                  <br></br>
                  <br></br>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      m: "15px"
                    }}
                  >
                    <iframe src="https://www.iberolunatravel.com/web/clausula/CLAUSULA_DE_RESPONSABILIDAD.pdf" width="1420px" id="content2" frameborder="0" height="770px"></iframe>                              
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
