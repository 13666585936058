import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Colors } from "../../styles/theme";
import {
  AppbarContainer,
  AppbarHeaderImage,
  MyList,
} from "../../styles/appbar";
import { useUIContext } from "../../context/ui";
import { Link, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";

export default function AppbarDesktop({ matches }) {
  const { setShowSearchBox } = useUIContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );
  const handleOnClickCatalogos = useCallback(
    () => navigate("/Catalogos", { replace: true }),
    [navigate]
  );
  const handleOnClickCircuitos = useCallback(
    () => navigate("/results/Circuitos/*", { replace: true }),
    [navigate]
  );
  const handleOnClickOfertas = useCallback(
    () => navigate("/results/Ofertas/*", { replace: true }),
    [navigate]
  );
  const handleOnClickQuienesSomos = useCallback(
    () => navigate("/quienessomos", { replace: true }),
    [navigate]
  );
  const handleOnClickContactenos = async () => {
    window.open("https://planesturisticos.com/la-empresa/", "_blank");
  };
  const handleOnClickReserva = useCallback(
    () => navigate("/consultareserva/*/*", { replace: true }),
    [navigate]
  );
  const handleOnClickPresupuesto = useCallback(
    () => navigate("/consultapresupuesto/*/*", { replace: true }),
    [navigate]
  );

  return (
    <AppbarContainer sx={{ backgroundColor: Colors.white, boxShadow: 1 }}>
      {/* <AppbarHeader variant="h4">My Bags</AppbarHeader> */}
      <Link to={"/home"}>
        <AppbarHeaderImage src="/images/PLanesTuristicos/LogoPLanesTuristicos.png"  alt="Planes Turísticos" title="Planes Turísticos"/>
      </Link>
      <MyList type="row">
        <ListItemButton align="center" onClick={handleOnClickHome}>
          <ListItemText align="center" primary="INICIO" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCatalogos}>
          <ListItemText align="center" primary="DESTINOS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCircuitos}>
          <ListItemText align="center" primary="¿DONDE DESEA VIAJAR?" />
        </ListItemButton>
        {/* <ListItemButton align="center" onClick={handleOnClickOfertas}>
          <ListItemText align="center" primary="BUSCADOR DE OFERTAS" />
        </ListItemButton> */}
        <ListItemButton align="center" onClick={handleOnClickContactenos}>
          <ListItemText align="center" primary="OFICINAS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleClick}>
          <ListItemText align="center" primary="CONSULTAS" />
        </ListItemButton>       
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
          <MenuItem onClick={handleOnClickReserva}>Consulta de Reservas</MenuItem>
          <MenuItem onClick={handleOnClickPresupuesto}>Consulta de Presupuesto</MenuItem>
        </Menu>
        {/*  <ListItemButton onClick={() => setShowSearchBox(true)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
        </ListItemButton> */}
      </MyList>
      {/* <Actions matches={matches} /> */}
    </AppbarContainer>
  );
}
