import {
  Grid,
  ThemeProvider,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";
import ReactPlayer from "react-player";
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';

function Europamundodesktop(props) {
  useEffect(() => {
    document.title = "Planes Turísticos - Europamundo";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />

            <Stack alignItems="center">
              <br></br>
              <br></br>
              <br></br>
              <AppTitleOrange
                variant={!props.matches ? "h3" : "h4"}
                sx={{ textAlign: "center" }}
              >
                ¿Qué es un circuito?
              </AppTitleOrange>
              <br></br>
              <br></br>

              <Stack
                sx={{
                  background: "#fff",
                  borderColor: Colors.blue_ibero,
                  padding: "15px",
                  borderRadius: "15px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  m: "15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ReactPlayer
                      url={"/Images/videos/CircuitoEuropamundo.mp4"}
                      light={"/Images/branding/fondoVideo.webp"}
                      width="100%"
                      height="500px"
                      controls={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <FlightTakeoffOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></FlightTakeoffOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Circuitos Turísticos"
                      />

                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                        Un circuito es un plan turistico diseñado para usted, con una oferta impresionante de rutas diferentes no solo por Europa si no también por todo el mundo. Hemos incluido los servicios que consideramos que puedan ser necesarios para que usted pueda disfrutar al máximo de sus vacaciones con nosotros. Todos nuestros circuitos son en autocar de lujo, irán acompañados de un guia de habla hispana para que no se pierda de los lugares más interesantes de cada país. Compare la calidad de nuestros servicios, estudie los precios, observe todas las actividades que incluimos con traslados, visitas, lugares y paseos agregados en nuestras rutas así como los hoteles que les ofrecemos. ¡Europamundo ofrece la mejor relación calidad/ precio del mercado!.
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>

            <Stack alignItems="center">
              <br></br>
              <br></br>
              <br></br>
              <AppTitleOrange
                variant={!props.matches ? "h3" : "h4"}
                sx={{ textAlign: "center" }}
              >
                ¿Por qué Europamundo?
              </AppTitleOrange>
              {/* <AppTitleBlue
                    variant={!props.matches ? "h5" : "h6"}
                    sx={{ textAlign: "center" }}
                  >
                    Nit: 830008421-5. Registro Nacional de Turismo número 36964
                  </AppTitleBlue> */}
              <br></br>
              <br></br>

              <Stack
                sx={{
                  background: "#fff",
                  borderColor: Colors.blue_ibero,
                  padding: "15px",
                  borderRadius: "15px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  m: "15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ReactPlayer
                      url={"https://youtu.be/B0wGQQ9JMUc"}
                      width="100%"
                      height="500px"
                      controls={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <TaskAltOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></TaskAltOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Garantías"
                      />

                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Salidas Garantizadas:
                                </span>
                              </strong>{" "}
                              a partir de 2 viajeros. En el caso de ser menos de
                              5 personas el total de viajeros apuntados podrán
                              efectuarse pequeños cambios en la ruta u ofrecerse
                              una ruta alternativa similar (vea Condiciones
                              Generales).
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Salidas durante todo el año:
                                </span>
                              </strong>{" "}
                              en gran parte de sus más de 1500 circuitos.{" "}
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Aseguramos el cumplimiento de toda la
                                  normativa vigente:
                                </span>
                              </strong>
                              El Premio a la Ética Turística otorgado por la OMT
                              lo garantiza.
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <CallSplitOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></CallSplitOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Flexibilidad"
                      />

                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Circuito Rotativo:
                                </span>
                              </strong>{" "}
                              Realice el circuito completo iniciando en el punto
                              que prefiera.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Excursiones opcionales:
                                </span>
                              </strong>{" "}
                              Si aún quiere completar su circuito, reserve con
                              antelación y garantice sus Excursiones Opcionales
                              a través de su página web MI VIAJE o app.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Paradas en circuito:
                                </span>
                              </strong>
                              Programe su parada durante el circuito retomándolo
                              en el mismo punto en otra salida posterior
                              (suplemento 42 €/ 47USD).
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Excursiones opcionales:
                                </span>
                              </strong>{" "}
                              Si aún quiere completar su circuito, reserve con
                              antelación y garantice sus Excursiones Opcionales
                              a través de su página web MI VIAJE o app.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Viaje a compartir:
                                </span>
                              </strong>
                              Si viaja sólo, por un suplemento de 35 €/ 40 USD,
                              le buscamos un compañero de habitación para que no
                              tenga que pagar el suplemento de habitación
                              individual (a partir de 7 noches de hotel. No en
                              minipaquetes).
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <SupportOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></SupportOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Satisfacción y seguridad"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Viaje respaldado
                                </span>
                              </strong>{" "}
                              Un guía acompañante les acompañará durante todo su
                              circuito. En muchas ciudades, además, los guías
                              locales les ampliarán y profundizarán su
                              conocimiento en las visitas de algunos lugares.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Viaje Seguro:
                                </span>
                              </strong>{" "}
                              Cobertura para todos los pasajeros durante su
                              viaje. Consulte la cobertura completa en
                              Condiciones Generales.
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <EmojiPeopleOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></EmojiPeopleOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Guías"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          Un guía acompañante les acompañará durante todo su
                          circuito. En muchas ciudades, además, los guías
                          locales les ampliarán y profundizarán su conocimiento
                          en las visitas de algunos lugares.
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <PublicOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></PublicOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Itinerarios"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <p>
                            En Europamundo buscamos incluir no sólo los lugares
                            más emblemáticos sino también los pequeños pueblos y
                            los más hermosos paisajes que serán parte
                            fundamental de su ruta apostando con ello por la
                            sostenibilidad y evitando la masificación.
                          </p>
                          <br></br>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Circuitos recorriendo varios países
                                </span>
                              </strong>{" "}
                              y centrados en los lugares mundialmente más
                              conocidos. Recomendados para las personas que
                              viajan por primera vez a estos destinos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Circuitos regionales:
                                </span>
                              </strong>{" "}
                              recorriendo en profundidad alguna región o país.
                              Recomendado para los más viajados.
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <AirportShuttleOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></AirportShuttleOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Transporte"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Circuitos en Autocar
                                </span>
                              </strong>{" "}
                              organizados para grupos, en nuestra moderna flota
                              de autocares y conducidos por profesionales.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Circuitos Privatizables en Van
                                </span>
                              </strong>{" "}
                              Escoja un circuito del catálogo con este sello y
                              conviértalo en Privado para su grupo de amigos o
                              familia.
                            </li>
                            <ul>
                              <li>Para grupos de 2 a 7 personas.</li>
                              <li>Realizados en van conducida por su guía.</li>
                              <li>
                                Con el mismo recorrido del circuito de catálogo
                                salvo pequeñas modificaciones.
                              </li>
                            </ul>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <LocationOnOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></LocationOnOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Nuestros destinos"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Europa:
                                </span>
                              </strong>{" "}
                              Más de 850 circuitos
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  China, Corea y Japón:
                                </span>
                              </strong>{" "}
                              Más de 150 circuitos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  América:
                                </span>
                              </strong>{" "}
                              más de 100 circuitos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Oriente Medio:
                                </span>
                              </strong>{" "}
                              más de 40 circuitos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Otros países de Asia, Oceanía, África u
                                  Oriente Medio:
                                </span>
                              </strong>{" "}
                              más de 40 circuitos.
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <ApartmentOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></ApartmentOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Hoteles"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          Siempre buscando la mejor relación calidad / precio en
                          función de la serie y el destino seleccionado. Revise
                          los hoteles propuestos para cada ruta a través de la
                          APP y encuentre la lista de hoteles confirmados para
                          usted unos días antes de la salida de su circuito en
                          su web MI VIAJE.
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        backgroundImage:
                          "url(https://iberolunatravel.online/images/fondocard.jpg)",
                        backgroundSize: "contain",
                      }}
                    >
                      <CardHeader
                        sx={{ paddingBottom: "0px" }}
                        avatar={
                          <RecommendOutlinedIcon
                            sx={{
                              color: Colors.blue_ibero,
                              fontSize: "40px",
                            }}
                          ></RecommendOutlinedIcon>
                        }
                        titleTypographyProps={{
                          variant: "h5",
                          color: Colors.blue_ibero,
                          fontWeight: "bold",
                        }}
                        title="Nuestras series"
                      />
                      <CardContent>
                        <div style={{ textAlign: "justify" }}>
                          <ul>
                            <li>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Serie Clásica:
                                </span>
                              </strong>{" "}
                              Nuestra serie de Mejor Calidad, Mayor oferta y
                              Mayor flexibilidad. Más de 500 circuitos por
                              Europa y América con muchos servicios incluidos
                              que consideramos fundamentales para su viaje.
                              Hoteles tipo 4* (en algún caso podrá utilizarse 3*
                              sup o 5*) con desayuno buffet. En algunos
                              circuitos podrá reservar el suplemento de comidas
                              de forma adicional. Otros circuitos tendrán
                              algunos almuerzos o cenas incluidos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Serie Turista:
                                </span>
                              </strong>{" "}
                              Nuestra serie Más Económica con más de 40
                              circuitos en Europa y América en hoteles de 3*
                              sup/ 4* con desayuno.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Serie Turista Más Incluido:
                                </span>
                              </strong>{" "}
                              Nuestra serie Más Completa. Con más de 70
                              circuitos diferentes en hoteles de la serie
                              Turista pero incluyendo muchos almuerzos o cenas
                              así como muchos servicios, paseos adicionales y
                              entradas para no tener gastos imprevistos.
                            </li>
                            <li style={{ marginTop: "10px" }}>
                              <strong>
                                <span style={{ color: Colors.orange_ibero }}>
                                  Serie Otros Destinos:
                                </span>
                              </strong>{" "}
                              Circuitos por Asia, O. Medio, África y Oceanía.
                              Con hoteles de 4*/5* pero con actividades y
                              servicios incluidos como nuestra serie Mas
                              Incluido (comidas, entradas, traslados,
                              excursiones, etc.).
                            </li>
                          </ul>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Europamundodesktop;
